<template>
    <v-dialog v-model="isOpen" persistent width="2000">
        <div style="background-color: #FFFFFF; padding: 0 1em 1em; position: relative; height: inherit;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"
                    class="px-4 py-4" @click.stop="cerrarModal" text rounded>
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <v-container>
                    <v-card-title class="d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <v-icon class="mr-2">mdi mdi-account-supervisor-circle-outline</v-icon>
                        <span class="font-weight-bold mr-2">Evaluación del contratista</span>
                        </div>
                        <v-card 
                        class="px-4 py-4 secondary"
                        >
                        <span style="color: white;">
                            {{`Calificación: ${this.guardarEvaluacion.promedioNota}`}}
                        </span>
                        </v-card>
                        
                    </v-card-title>
                    <div class="mr-4 mb-4">
                        <span class="font-weight-bold">Nombre del proveedor: {{ proveedor?.nombre_comercial }}</span>
                    </div>
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Criterios</th>
                                                <th v-for="i in 10" :key="i">{{ i }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in listaCriterios" :key="index">
                                                <td>{{ item.nombre }}</td>
                                                <td v-for="i in 10" :key="i">
                                                    <v-checkbox
                                                        v-model="notasCriterios[item.id]"
                                                        :true-value="i"
                                                        :false-value="null"
                                                        @change="criterioSeleccionado(item, i)"
                                                        @input="$v.notasCriterios.$touch()"
                                                    >
                                                    </v-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>
                            <div class="mt-4 d-flex justify-end">
                                <v-btn
                                    class="secondary"
                                    v-model="generarNotaGlobal"
                                    @click="generarNota"
                                    >
                                   Generar calificación
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" class="px-0 py-0 flex-shrink-1 flex-grow-1">
                            <v-textarea
                                class="ml-4 mr-4"
                                label="Observación"
                                outlined
                                row-height="30"
                                v-model="guardarEvaluacion.observacion" 
                                rows="4"
                                auto-grow
                                clearable
                                counter="255"
                                maxlength="255"
                            />
                        </v-col>
                        
                        <v-col cols="12">
                            <v-file-input
                                accept="application/pdf"
                                clearable
                                label="Acta de inconformidad (Opcional)"
                                outlined
                                class="py-2"
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                v-model="guardarEvaluacion.adjunto"
                            />
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-end mt-4" cols="12" >
                            <v-btn class="flex-grow-1 flex-shrink-1" @click.stop="cerrarModal" outlined color="secondary">Regresar</v-btn>
                            <v-btn class="flex-grow-1 flex-shrink-1 ml-4" @click=guardarCalificacion color="secondary">Registrar evaluación</v-btn>
                        </div>
                </v-container>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isNil } from 'lodash';
import { convertToFormData } from "@/utils/data";


export default {
    name: 'evaluacionModal',
    data: () => ({
        generarNotaGlobal: false,
        id_etapa: null,
        notasCriterios: {},
    }),
    props: {
        isOpen: { type: Boolean },
        proveedor: { type: Object },
    },
    computed: {
        ...mapState("SeguimientoContractual", ["evaluacionDefault","guardarEvaluacion", "criterios"]),

        listaCriterios() {
            return this.criterios.data;
        }
    },
    methods: {
        ...mapActions("SeguimientoContractual", ["resetForm","agregarCalificacion", "cargarCriterios"]),
        async guardarCalificacion() {
            const totalCriterios = this.criterios.data.length;
            const criteriosCalificados = Array.from(Object.values(this.notasCriterios)).filter((nota) => !isNil(nota)).length;
            if(this.id_etapa){
                await this.enviarEvaluacion();
            }else{
                if (!this.generarNotaGlobal) {
                    return this.pushAppMessage({ type: 'error', message: 'Se necesita generar la nota para obtener la calificacion del contratista.' });
                } else if (totalCriterios !== criteriosCalificados) {
                    return this.pushAppMessage({ type: 'error', message: 'Se deben llenar todos los campos de la calificación' });
                } else {
                    this.cerrarModal();
                }
            }

            this.guardarEvaluacion.tieneUnaEvaluacion = true
        },
        async enviarEvaluacion(){
            const formData = convertToFormData({
                observacion: this.guardarEvaluacion.observacion,
                notas: this.guardarEvaluacion.notas,
                id_contrato:  Number(this.$route.params.id_orden),
                id_proveedor: Number(this.$route.params.idProveedor),
                adjunto:this.guardarEvaluacion.adjunto,
                promedioNota: this.guardarEvaluacion.promedioNota
            })
            await this.services.SeguimientoContractual.guardarNota(this.id_etapa,formData);

            this.$emit('recargar')
            this.cerrarModal();
        },
        criterioSeleccionado(item, nota) {

            if (isNil) {
                const index = this.guardarEvaluacion.notas.findIndex((i) => i.id === item.id);
                if (index !== -1) this.guardarEvaluacion.notas.splice(index, 1)
            }
            this.guardarEvaluacion.notas.push({
                id: item.id,
                nombre: item.nombre,
                nota
            });
        },
        generarNota() {
            const totalCriterios = this.criterios.data.length;
            const criteriosCalificados = Array.from(Object.values(this.notasCriterios)).filter((nota) => !isNil(nota)).length;

            if (totalCriterios !== criteriosCalificados) {
                this.pushAppMessage({ type: 'error', message: 'Se deben llenar todos los campos para generar la calificación' });
            } else {
                this.generarNotaGlobal = true;
                const notas = Object.values(this.notasCriterios);
                let sumaNotas = 0;

                notas.forEach(nota => {
                    sumaNotas += nota;
                });

                this.guardarEvaluacion.promedioNota = +(sumaNotas / notas.length).toFixed(2);
            }
        },
        cerrarModal() {
            this.$emit('close');
        }
    },
    created() {
        this.id_etapa = this.$route.params.id_etapa;
    },
}
</script>
